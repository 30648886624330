import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Task", "Hours per Day"],
  ["Developer", 30],
  ["UX Designer", 7.5],
  ["System Architect", 7.5],
  ["QA Engineer", 15],

];

export const options = {
  is3D: true,
};

export default function PieChart() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"600px"}
    />
  );
}
