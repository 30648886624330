import React, { Component } from "react";
import PropTypes from "prop-types";


const Video = ({src}) => (
  <div className="video-wrapper">
    <iframe class="responsive-iframe"
     src={src}
     title="YouTube video player"
      frameborder="0"
       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
       autoPlay="1"
        allowfullscreen></iframe>
  </div>
);


export default Video;