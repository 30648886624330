import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/Navbar';
import AboutPage from './pages/AboutPage';
import ArticlePage from './pages/ArticlePage';
import ArticleListPage from './pages/ArticleListPage';
import ProjectListPage from './pages/ProjectListPage';
import NotFoundPage from './pages/NotFoundPage';
import MathsPage from './pages/Maths';
import {styles} from './styles';
import ContactPage from './pages/ContactPage';
import Footer from './components/Footer';
import Header from './components/Header';

import ProjectPage from './pages/ProjectPage';
import NavBar2 from './components/Navbar2';

function App() {
  return (
    <BrowserRouter>
    <div className='wrapper'>
          <Header/>
          </div>
          <div id="page-body">
          <Routes>
            <Route path="/" element ={<AboutPage/>} />
            <Route path="/projects" element={<ProjectListPage/>}/>
            <Route path="/projects/:projectId" element={<ProjectPage/>} />
            <Route path="/articles" element={<ArticleListPage/>}/>
            <Route path="/articles/:articleId" element={<ArticlePage/>} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/contact" element ={<ContactPage/>} />
            <Route path="/maths" element={<MathsPage />} />
          </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}


export default App;
