import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NotFoundPage from './NotFoundPage';
import CommentsList from '../components/CommentsList';
import AddCommentFormProject from '../components/AddCommentFormProject';
import projects from './projects-content';
import Button from '@mui/material/Button';


const ProjectPage = () => {
    const [projectInfo, setProjectInfo] = useState({ upvotes: 0, comments: [] });
    const { projectId } = useParams();

    useEffect(() => {
        const loadProjectInfo = async () => {
            const response = await axios.get(`/api/projects/${projectId}`);
            const newProjectInfo = response.data;
            setProjectInfo(newProjectInfo);
        }

        loadProjectInfo();
    }, []);

    const project = projects.find(project => project.name === projectId);

    const addUpvote = async () => {
        const response = await axios.put(`/api/projects/${projectId}/upvote`);
        const updatedProject = response.data;
        setProjectInfo(updatedProject);
    }

    if (!project) {
        return <NotFoundPage />
    }

    return (
        <>
                 
                  <div id="parent">
  <div id="wide"><span className="font-title" >
            {project.title}<br></br></span></div>
  <div id="narrow">
    <Button variant="outlined" style={{ backgroundColor: 'black', width: '120px', height: '50px'}}onClick={addUpvote}>Upvote
    </Button>
</div>
            </div>

        <div className="upvotes-section">
            <br></br> 

            <p>This project has {projectInfo.upvotes} upvote(s)</p>
        </div>
        {project.content.map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
        ))}
        <AddCommentFormProject
            projectName={projectId}
            onProjectUpdated={updatedProject => setProjectInfo(updatedProject)} />
        <CommentsList comments={projectInfo.comments} />
        </>
    );
}

export default ProjectPage;