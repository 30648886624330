const ContactSection = () => {
    return(
        <div className="card">
            <span className="font-link">
            Get in touch with me via social media or email.
<br></br><br></br>
</span>
        </div>
    )
 };
 
 export default ContactSection;