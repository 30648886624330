import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController
);

const BarChart = () => {
    const data = {
      labels: [ 'Python', 'Django', 'React/React-native', 'Javascript', 'Java', 'Typescript', 'Salesforce CRM',],
      datasets: [
        {
          label: 'Data Series 1',
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
          borderColor: 'rgba(180, 180, 180)',
          borderWidth: 1,
          data: [7.5, 9.5, 8, 7, 5, 4, 7],
        },
      ],
    };
  
    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
              }
            },
        
        
      scales: {
        y: {
          grid: {
            type: "linear",
            display: false // No display of grid lines for the x-axis
        },
        ticks: {
            font: {
                size: 20,
            }
        }
        
    },
    x: {
        grid: {
            type: "linear",

          display: false // No display of grid lines for the x-axis
      },
      ticks: {
        font: {
            size: 17,
        }
    }
      }
    },
    }
              

    const colours = {
        elements: {
          bar: {
            borderRadius: 40,
            borderWidth: 0.7,
          },
        },
      };
  
    return (
      <div>
        <Bar data={data} options={options} />
      </div>
    );
  };
  
  export default BarChart;