const StorySection = () => {
    return(
        <div className="card">
            <span className="font-link">
            My journey into tech has been an uncoventional one but I truly believe that every obstacle and challenge pointed me to where 
            I am now. From a young age I was obsessed with gadgets. I remember the day my dad came home with a Panasonic PC like it was yesterday
            and it remains one of my fondest early childhood memories. I quickly became fascinated with understanding 
            how games such as Forza and Sonic the Hedgehog that I spent endless hours on worked. <br></br><br></br>As I grew older, I found this interest diluted by  
            aspirations to pursue a career that would make my family and friends proud as opposed to one that would fulfill me.
             Unlike many kids growing up, I didn't really have a favourite subject as school and so I made the decision to study Geography during my undergraduate degree
             since it was the subject that I believed would offer me the most diverse career options into the future,
             and that it certainly did... <br></br><br></br>
            <a href={`/articles/my-story`}>Continue reading my story </a>  </span>
        </div>
    )
 };
 
 export default StorySection;