import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
import { Link } from 'react-router-dom';

 
const Footer = () => {
    return (
        <Box>
            <FooterContainer>
                <Row>
                    <Column>
                    <Link to="/">
                        <Heading>About</Heading>
                        </Link>
                    </Column>
                    <Column>
                    <Link to="projects">
                        <Heading>Projects</Heading>
                        </Link>
                    </Column>
                    <Column>
                    <Link to="/articles">
                        <Heading>Blog</Heading>
                        </Link>
                    </Column>
                    <Column>
                    <Link to="/contact">
                        <Heading>Contact</Heading>
                        </Link>
                                           </Column>
                </Row>
            </FooterContainer>
        </Box>
    );
};
export default Footer;