import projects from "./projects-content";
import ProjectsList from "../components/ProjectsList";
import {styles} from '../styles';
import { motion, useScroll } from 'framer-motion'

const ProjectListPage = () => {
    return (
        <>
      <motion.div id="narrowtextleft" initial={{ opacity: 0}} animate ={{ opacity: 1 }}  transition={{ delay: 0.5 }} viewport={{ once: true }} >  <h1 style={styles.Title}>projects.</h1> 
      </motion.div>
      <div className="sections">
      <motion.div  initial={{ opacity: 0}} animate ={{ opacity: 1 }}  transition={{ delay: 2 }} viewport={{ once: true }} >  
      <ProjectsList projects={projects}/>
      <br></br>
      <br></br>
      <br></br>


        </motion.div>
        </div>

        </>
    );
}

export default ProjectListPage;
