import React from 'react';
import NavBar from '../components/Navbar'
import AboutSection from '../text_sections/about'
import StorySection from '../text_sections/story'
import Video from "../components/Video"
import {styles} from '../styles';
import { Chart } from "react-google-charts";
import PieChart from '../components/PieChart';
import { motion, useScroll } from 'framer-motion'
import BarChart from '../components/BarChart';
import MediaQuery, { useMediaQuery } from 'react-responsive'



const AboutPage = () => {


return (
    <>
<div className='wrapper'>
  <div className='dronecontainer'>
  <div className='logo-mobile'>
<img src={require('../logo-modified.png')} width="40%" />
</div>
</div>
      <motion.div id="narrowtextleft" initial={{ opacity: 0}} animate ={{ opacity: 1 }}  transition={{ delay: 1 }} viewport={{ once: true }} >  <h1 style={styles.Title}>about.</h1> 
</motion.div>
  
  <motion.div initial={{ opacity: 0}} animate ={{ opacity: 1 }} transition={{ delay: 2.5 }} className="about-list-item"> 
  <AboutSection />
<br></br>
<div className='dronecontainer'>
  <img className="img-me" src={require('../static/me.jpeg')} />
  </div>
  <br></br>
  <br></br>
  <br></br>
  <br></br>


  </motion.div>
 
  <div className="about-list-item"> 

  <div id="parent">
  <div id="wide"><span className="font-title" >
          </span></div>
  <div id="narrowtextright">  <h1 style={styles.SubTitle}>experience.</h1>
</div>
            </div>

            <motion.div initial={{ x: 400}} whileInView={{ x: 0}} viewport={{ once: true }}> 

  <PieChart  style={{ 'margin-left': '350px'}}/>
  </motion.div>
</div>
  <div className="about-list-item"> 

<div  id="parent">
<div id="narrowtextleft">  <h1 style={styles.SubTitle}>my skills.</h1>
<div id="wide"><span className="font-title" >
         <br></br></span></div>
</div>
          </div>

          <motion.div initial={{ x: -300}} whileInView={{ x: 0}} viewport={{ once: true }}>  
<div className='sections'>
<BarChart/>
</div>
</motion.div>
</div>

<div className="about-list-item" initial={{ opacity: 0}} whileInView={{ opacity: 1}} viewport={{ once: true }}>  

<div id="parent">
<div id="wide"><span className="font-title" >
         <br></br></span></div>
<div id="narrowtextright">  <h1 style={styles.SubTitle}>my story.</h1>

</div>

          </div>
          <div className='sections'>
          <motion.div  initial={{ x: 500}} whileInView={{ x: 0}} viewport={{ once: true }}> 
          <StorySection />


</motion.div>
</div>
</div>


  <motion.div className="about-list-item" initial={{ opacity: 0}} whileInView={{ opacity: 1}}> 

  <div id="parent">
  <div id="narrowtextleft">  <h1 style={styles.SubTitle}>drone piloting. </h1> 
  <h2 style={styles.SubText}>*on DJI Air 2s </h2> </div>
<div id="wide"><span className="font-title" >
           <br></br></span></div>
           </div>
<div className='dronecontainer'>
  <img className='outline2' src={require('../static/cirlcle_air2s.png')} />
  </div>
  <div className='wrapper'></div>
  <div className='sections'>
  <Video src="https://www.youtube.com/embed/Rrt2-ZSYMKE?si=BZ5mQzBYVk7J1MJ9" />
  <br></br>
  <br></br>
  <br></br>
<div>
  <Video src="https://www.youtube.com/embed/zFPG1G2vAG8?si=WAgDIWoOIOAtJTWJ"/>
  <br></br>
  <br></br>
  <br></br>
  <Video src="https://www.youtube.com/embed/mrGKi9DgW8M?si=nOx6csHOykbaj3Mj"/>

</div>
</div>

  </motion.div>

  <br></br>
  <br></br>
  </div>
  </>
);
}
document.body.style.zoom = 0.75

export default AboutPage;