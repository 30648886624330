import React from 'react';
import NavBar from '../components/Navbar'
import ContactSection from '../text_sections/contact'
import {styles} from '../styles';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import { Phone, Favorite, Person, House, Home, Instagram, Facebook, LinkedIn, Twitter  } from '@mui/icons-material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ImageCard from '../components/ImageCard'
import { motion, useScroll } from 'framer-motion'


const ContactPage = () => {
  return (
      <>
      <motion.div id="narrowtextleft" initial={{ opacity: 0}} animate ={{ opacity: 1 }}  transition={{ delay: 0.5 }} viewport={{ once: true }} >
    <h1 style={styles.Title}>contact.</h1>
    </motion.div>
    <div className='sections'>
    <motion.div  initial={{ opacity: 0}} animate ={{ opacity: 1 }}  transition={{ delay: 2 }} viewport={{ once: true }}>
    <ContactSection />
  <br></br>
  <br></br>
<div className="contactcontainer">
  <div className="contactcontainer2">
  <Link to="https://twitter.com/shaun_dbs"> 
<ImageCard appname="Twitter" image={require('../static/x.jpg')} appdesc="Keep in the loop with cultural, sport and drone conversations.
I am an active user and engage with many creatives and stakeholders on the platform. "/>
<br></br>
<br></br>
<br></br>

</Link>
<Link to="https://www.instagram.com/sharpest.jpg?igsh=MTQ2d204ZG5rbWZreg%3D%3D&utm_source=qr"> 
<ImageCard appname="Instagram" image={require('../static/ig.jpg')} appdesc="This is the source of the latest visual media that I share relating
to both my photgraphy and videography work. DM for collaborations and features. "/>
</Link>
<br></br>
<br></br>

</div>


<div className="contactcontainer2">
<Link to='https://www.linkedin.com/in/shaun-bentum-siripi-284b3b147/'> 
<ImageCard appname="Linkedin" image={require('../static/linkedIn.jpeg')}  appdesc="Follow my journey into tech as well as stay tuned in with
lastest updates around Sharpest Limitied. I also regularly share my thoughts on trending topics including AI and automated processes. "/>
</Link>
<br></br>
<br></br>
<Link to='https://github.com/shaundbs' style={{ color: '#111ef7' }}>  
<ImageCard appname="Github" image={require('../static/git.jpg')} appdesc="The home of some of my most valued public projects. From React to Django,
here you can find and install the repositiories for some of my most treasured work to date. "/>
</Link>
<br></br>
<br></br>
<br></br>
</div>
</div>

  </motion.div>
  </div>

    </>
  );
  }
  
  export default ContactPage;