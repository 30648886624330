import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import { Phone, Favorite, Person, House, Home, Instagram, Facebook, LinkedIn, X  } from '@mui/icons-material';
import { StyleSheet} from 'react';
import {styles} from '../styles';
import { useState } from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Avatar } from '@mui/material';


const NavBar = () => {

return(

<Tabs className='navbar' style={{ width: '100%',  color: "white", backgroundColor:"black", padding: '0.5%'}} >
     <div className='flexboxA'>
<Link to="/" style={{ color: 'white' }}>  
<Tab icon={<Avatar sx={styles.LogoIcon} src={require('../logo-modified.png')} />} textColor='white' color="white" />
</Link>

<div className='flexboxA'>

<Link to="/" style={{ color: 'white' }}>  
<Tab icon={<Person sx={styles.NavbarIcon} />} textColor='white' fontSize="large" label="About" color="white" />
</Link>
<Link to="projects" style={{ color: 'white' }}> 
<Tab icon={<LightbulbIcon  sx={styles.NavbarIcon} />} textColor='white' fontSize="large" label="Projects"/>
</Link>
<Link to="/articles" style={{ color: 'white' }}>  
<Tab icon={<LibraryBooksIcon sx={styles.NavbarIcon} />}  textColor='white' fontSize="large" label="Blog" />
</Link>
<Link to="/contact" style={{ color: 'white' }}>
  <Tab  icon={<Phone  sx={styles.NavbarIcon} />} textColor='white' fontSize="large" label="Contact" />
  </Link>
  </div>
  <div className='wrap3'>

  <Link to="https://twitter.com/shaun_dbs"> 
  <Tab icon={<X sx={styles.SocialIcon}  />} 
       fontSize="large"  />
  </Link>
  <Link to="https://www.instagram.com/sharpest.jpg?igsh=MTQ2d204ZG5rbWZreg%3D%3D&utm_source=qr"> 
  <Tab icon={<Instagram sx={styles.SocialIcon}  />} 
       fontSize="large"  />
  </Link>
  <Link to='https://www.linkedin.com/in/shaun-bentum-siripi-284b3b147/'> 
  <Tab icon={<LinkedIn sx={styles.SocialIcon} />}
       fontSize="large" />
  </Link>
  <Link to='https://github.com/shaundbs' style={{ color: '#111ef7' }}>  
  <Tab icon={<GitHubIcon  sx={styles.SocialIcon} />}
       fontSize="large" />
  </Link>
  </div>
  </div>
</Tabs>

    );
}



export default NavBar;