const AboutSection = () => {
    return(
        <div className="sections">
            <span className="font-link">
            I am a <span className="thick">Full-Stack Software Engineer</span> based in London, UK.<br></br><br></br>
            Over the last 5+ years I have gained extensive hands-on experience in the tech industry on a range of projects across healthcare,
            education and finance sectors. I have delivered large-scale features for companies including <span className="thick">IBM,
            Deutsche Bank</span> and the <span className="thick">NHS.</span><br></br><br></br>More recently, I have launched a tech company in 
            <span className="thick"> Sharpest Limited</span> that specialises in mobile app development using React-native and Salesforce CRM based
             solutions.<br></br><br></br>In my spare time, I also produce multimedia as a photgrapher/videographer. I am a certified CAA  
             (Civil Aviation Authority) drone pilot, a hobbie that I picked up in 2023 and I also enjoy working out and hiking with friends.<br></br><br></br></span>
        </div>
    )
 };
 
 export default AboutSection;