import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Phone, Favorite, Person, House, Home, Instagram, Facebook, LinkedIn  } from '@mui/icons-material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LightbulbIcon from '@mui/icons-material/Lightbulb';


export default function ActionAreaCard({appname, appdesc, image}) {
  return (
    <Card sx={{ width: 450,}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={image}
          appname={appname}
          appdesc={appdesc}
          fontSize='30px'
          
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {appname}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {appdesc}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}