import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./HeaderStyles";
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import { Phone, Favorite, Person, House, Home, Instagram, Facebook, LinkedIn, X  } from '@mui/icons-material';
import { StyleSheet} from 'react';
import {styles} from '../styles';
import { useState } from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Avatar } from '@mui/material';
 
const Header = () => {
    return (
        <Box>
            <FooterContainer>
                <Row>
                    <div className="deskContent">
                <Column>
                    <Link to="/" style={{ color: 'white' }}>  
    <Tab icon={<Avatar sx={styles.LogoIcon} src={require('../logo-modified.png')} />} textColor='white' color="white" margin-right="50%" />
    </Link>
                    </Column>
                    </div>
                    <Column>
                    <Link to="/" style={{ color: 'white' }}>  
<Tab icon={<Person sx={styles.NavbarIcon} />} textColor='white' fontSize="large" label="About" color="white" />
</Link>
                    </Column>
                    <Column>
                    <Link to="projects" style={{ color: 'white' }}> 
<Tab icon={<LightbulbIcon  sx={styles.NavbarIcon} />} textColor='white' fontSize="large" label="Projects"/>
</Link>
                    </Column>
                    <Column>
                    <Link to="/articles" style={{ color: 'white' }}>  
<Tab icon={<LibraryBooksIcon sx={styles.NavbarIcon} />}  textColor='white' fontSize="large" label="Blog" />
</Link>
                    </Column>
                    <Column>
                    <Link to="/contact" style={{ color: 'white' }}>
  <Tab  icon={<Phone  sx={styles.NavbarIcon} />} textColor='white' fontSize="large" label="Contact" />
  </Link>
                                           </Column>
                </Row>
            </FooterContainer>
        </Box>
    );
};
export default Header;
