import { useState } from "react"
import logo from "../logo.jpg"


const MathsPage = () => {

    const [firstNumber,setFirstNumber] = useState()
    const [secondNumber,setSecondNumber] = useState()
    const [total, setTotal] = useState(0)
    const [show, setShow] = useState(true)

    const AddNumbers = () => {

        return (
        setTotal(parseInt(firstNumber) + parseInt(secondNumber))
        )
    }
    
    return(
        <>
{/* 
        
 <p>First Number: 
<input onChange={(e) => setFirstNumber(e.target.value)}></input></p>
<br></br>
 <p>Second Number: 
 <input onChange={(e) =>setSecondNumber(e.target.value)}></input></p>
 <br></br>
 <button onClick={AddNumbers}>Add numbers</button>
 <br></br>
 <p>Total: {total}</p>
 <br></br>
 <br></br>


 

 <button onClick={() => setShow((show) => !show)}>Show/Hide</button>
{show ? <h1>Welcome to the test</h1> : null}
  */}
         </>
    )
}

export default MathsPage;
