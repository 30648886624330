const NavbarIcon = {
    fontSize: '350%',
        'color': 'white',
        'textColor':'white'
  }

  const LogoIcon = {
    width: '70%',
    height: '70%',
        'color': 'white',
        'textColor':'white',
        'margin-right': '80px'
  }
  const SocialIcon = {
    'align-self': 'end',
     'color':'white',
    fontSize: '350%',
  }

  const Title = {
    fontSize: 100,
    color: '#8a8a8a ',
  }

  const SubTitle = {
    fontSize: 50,
    color: '#8a8a8a',
  }

  const SubText = {
    fontSize: 25,
    color: '#8a8a8a',
  }

  export const styles = {
    NavbarIcon: NavbarIcon,
    Title:Title,
    SocialIcon: SocialIcon,
    SubTitle: SubTitle,
    SubText: SubText,
    LogoIcon: LogoIcon,
  }