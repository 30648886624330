import articles from "./article-content";
import ArticlesList from "../components/ArticlesList";
import {styles} from '../styles';
import { motion, useScroll } from 'framer-motion'

const ArticleListPage = () => {
    return (
        <>
        <div className="wrapper">
          <div className="sections">
      <motion.div id="narrowtextleft" initial={{ opacity: 0}} animate ={{ opacity: 1 }}  transition={{ delay: 0.5 }} viewport={{ once: true }} >  <h1 style={styles.Title}>blog.</h1> 
      </motion.div>
      <motion.div  initial={{ opacity: 0}} animate ={{ opacity: 1 }}  transition={{ delay: 2 }} viewport={{ once: true }} >  
      <ArticlesList articles={articles}/>
      <br></br>
      <br></br>
      <br></br>



        </motion.div>
        </div>
        </div>
        </>
    );
}

export default ArticleListPage;
